import { Box, Stack } from '@mui/material';
import { Bubble } from './Bubble';
import { TextToSpeech } from './TextToSpeech';
import { useLanguage } from '../../contexts/LanguageContext';

export function PenGwen({ children, promptResult, textToSpeech = false, ...props }) {
  const { language, setLanguage } = useLanguage();

  const languages = [
    { displayValue: '🇺🇸 English', code: 'en-US' },
    { displayValue: '🇪🇸 Spanish', code: 'es-ES' },
    { displayValue: '🇨🇳 Chinese (Mandarin)', code: 'zh-CN' },
    { displayValue: '🇭🇰 Chinese (Cantonese)', code: 'zh-HK' },
    { displayValue: '🇻🇳 Vietnamese', code: 'vi-VN' },
    { displayValue: '🇸🇦 Arabic', code: 'ar-SA' },
    { displayValue: '🇵🇭 Tagalog', code: 'tl-PH' },
    { displayValue: '🇰🇷 Korean', code: 'ko-KR' },
    { displayValue: '🇭🇹 Haitian Creole', code: 'ht-HT' },
    { displayValue: '🇵🇹 Portuguese', code: 'pt-PT' },
    { displayValue: '🇷🇺 Russian', code: 'ru-RU' },
    { displayValue: '🇵🇰 Urdu', code: 'ur-PK' },
    { displayValue: '🇵🇱 Polish', code: 'pl-PL' },
  ];

  return (
    <Box {...props}>
      {promptResult && (
        <Box sx={{ textAlign: 'center', marginBottom: 1, marginTop: 2 }}>
          <Bubble>
            {promptResult}
            <br />
          </Bubble>
        </Box>
      )}
      <Box sx={{ paddingY: 2, textAlign: 'center', position: 'relative' }}>
        <picture>
          <source srcSet="/gwen.png, /gwen@2x.png 2x" />
          <img alt="Gwen - a helping tutor" />
        </picture>
      </Box>

      <Stack direction="column" alignItems="center" gap={1}>
        <Stack direction="row" gap={1}>
          <select
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
            style={{
              padding: '2px 5px',
              fontSize: '14px',
              borderRadius: '4px',
              border: '1px solid #ccc',
            }}
          >
            {languages.map((lang) => (
              <option key={lang.code} value={lang.code}>
                {lang.displayValue}
              </option>
            ))}
          </select>
          {textToSpeech && <TextToSpeech text={promptResult} language={language} />}
        </Stack>
        {children}
      </Stack>
    </Box>
  );
}
