import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { API_BASE_URL } from '../../../config';

export const useUnsubmitAssignment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ assignmentId, studentId }) =>
      axios.patch(
        `${API_BASE_URL}/api/students/${studentId}/assignments/${assignmentId}/unsubmit`,
        {},
        { withCredentials: true }
      ),
    onSuccess: (_, { assignmentId }) => {
      queryClient.invalidateQueries(['assignment', { assignmentId }]);
    },
  });
};
