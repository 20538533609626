import { Button, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';

export function ButtonWithTooltip({
  label,
  tooltip,
  icon,
  to,
  onClick,
  fullWidth = false,
  justifyContent = 'start',
  disabled = false,
  variant = 'text',
  tooltipPlacement = 'left',
}) {
  return (
    <Tooltip title={tooltip} placement={tooltipPlacement}>
      <Button
        component={Link}
        fullWidth={fullWidth}
        style={{ cursor: 'pointer', opacity: disabled ? 0.5 : 1 }}
        startIcon={icon}
        sx={{
          justifyContent,
          '& .MuiButton-startIcon': { marginRight: 0.5 },
        }}
        to={disabled ? undefined : to}
        onClick={disabled ? undefined : onClick}
        size="small"
        variant={variant}
      >
        {label}
      </Button>
    </Tooltip>
  );
}
