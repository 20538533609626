import { useParams } from 'react-router-dom';
import { ButtonGroup, Container, Paper, Stack, TableBody, TableHead, Toolbar, Typography } from '@mui/material';
import {
  Assignment as AssignmentIcon,
  Edit as EditIcon,
  ContentPaste as AnalyticsIcon,
  Share as ShareIcon,
} from '@mui/icons-material';
import ReplayIcon from '@mui/icons-material/Replay';
import { format } from 'date-fns';
import { isEmpty } from 'ramda';
import CircularProgress from '@mui/material/CircularProgress';
import { ROUTES } from '../../../routes';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { LoadingError } from '../../../components/LoadingError';
import { TestAssignmentMenu } from '../components/TestAssignmentMenu';
import { Breadcrumb } from '../../../components/layout/Breadcrumb';
import { Table, Tr, Td, Th } from '../../../components/layout/Table';
import { ButtonWithTooltip } from '../../../components/layout/ButtonWithTooltip';
import { UserAvatar } from '../../../components/Avatar';
import { BoolValue } from '../../../components/BoolValue';
import { AssignmentTypeText, getAssignmentStatus } from '../../../helpers/assignment';
import { useAssignment } from '../api/useAssignment';
import { UserAssignmentStatus } from '../../../helpers/enums';
import { useUnsubmitAssignment } from '../api/useUnsubmitAssignment';

export function AssignmentIndexPage() {
  const { assignmentId } = useParams();

  const { data: assignment, isLoading, isError, error } = useAssignment(assignmentId);

  const unsubmitMutation = useUnsubmitAssignment();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return <LoadingError error={error} />;
  }

  return (
    <Container>
      <Breadcrumb assignmentId={assignmentId} assignmentTitle={assignment.title} />
      <Paper sx={{ padding: 2, marginBottom: 2 }}>
        <Toolbar sx={{ pl: { sm: 0 }, pr: { xs: 0, sm: 1 } }}>
          <Typography variant="h6" sx={{ flexGrow: 1, marginLeft: 0 }}>
            {assignment.title}
          </Typography>
          <ButtonGroup>
            <TestAssignmentMenu assignment={assignment} />
            <ButtonWithTooltip
              label="Edit"
              tooltip="You can edit assignment only if there are no assignees yet."
              tooltipPlacement="top"
              icon={<EditIcon />}
              to={ROUTES.editAssignment(assignmentId)}
              disabled={!isEmpty(assignment.students)}
              variant="outlined"
            />
            <ButtonWithTooltip
              label="Assign"
              tooltip="Assign this assignment to students"
              tooltipPlacement="top"
              icon={<ShareIcon />}
              to={ROUTES.assignToAssignment(assignment.id)}
              variant="contained"
            />
          </ButtonGroup>
        </Toolbar>
        <Stack direction="column">
          <Typography style={{ fontStyle: 'italic', paddingBottom: '20px' }}>{assignment.instructions}</Typography>
          <Stack direction="column" gap={2}>
            <Typography>
              <strong>Type:</strong> {AssignmentTypeText[assignment.type]}
            </Typography>
            {assignment.template && (
              <Typography>
                <strong>Template:</strong> {assignment.template.name}
              </Typography>
            )}
            {/* <Typography>
              <strong>Classes:</strong> TODO
            </Typography> */}
            <Typography>
              <strong>With Gwen AI help?:</strong> <BoolValue value={assignment.useGwen} />
            </Typography>
            <Typography>
              <strong>Generate AI Image:</strong> <BoolValue value={assignment.generateImage} />
            </Typography>
            <Typography>
              <strong>Grade:</strong> {assignment.grade ? assignment.grade : '-'}
            </Typography>
            <Typography>
              <strong>Due date:</strong> {assignment.dueDate ? format(assignment.dueDate, 'P') : '-'}
            </Typography>
          </Stack>
        </Stack>
      </Paper>
      <Table
        variant="outlined"
        caption="Assigned Users & Submissions"
        ariaLabel="Submissions"
        noData={assignment.students.length === 0}
      >
        <TableHead>
          <Tr>
            <Th>#</Th>
            <Th align="left">Student name</Th>
            <Th>Class</Th>
            <Th>Status</Th>
            <Th>Submitted Work Actions</Th>
          </Tr>
        </TableHead>
        <TableBody>
          {/* TODO: Change serialization, do not use assignment.studentAssignments and get back to this basic mapping */}
          {/* {assignment.students.map((student) => { */}
          {assignment.studentAssignments.map((studentAssignment, index) => {
            const student = studentAssignment.user;
            const externalTextPastePercentage = student.externalTextPastePercentage > 20;
            const assignedToClasses = student.courseUsers.map((courseUser) => courseUser.course.name);
            // TODO: There might be student without courseUsers (because removed from a class?) and then it fails
            const { courseId = -1 } = student.courseUsers?.[0] || {}; // TODO: what if multiple classes

            return (
              <Tr key={student.id}>
                <Td>{index + 1}</Td>
                <Td align="left">
                  <ButtonWithTooltip
                    label={student.name}
                    tooltip="View student profile"
                    icon={<UserAvatar alt={student.name} src={student.avatarUrl} />}
                    to={ROUTES.showStudent(courseId, student.id)}
                  />
                </Td>
                <Td>{assignedToClasses.join(', ')}</Td>
                <Td>{getAssignmentStatus(studentAssignment.status)}</Td>
                <Td>
                  <Stack direction="row" spacing={1} justifyContent="center">
                    {studentAssignment.status !== UserAssignmentStatus.CREATED && (
                      <ButtonWithTooltip
                        label="View"
                        tooltip={
                          student.assignmentStatus === UserAssignmentStatus.SUBMITTED
                            ? 'View submitted work'
                            : 'View work in progress'
                        }
                        icon={<AssignmentIcon />}
                        to={ROUTES.showAssignmentSubmission(assignment.id, student.id)}
                      />
                    )}
                    {studentAssignment.status === UserAssignmentStatus.SUBMITTED && (
                      <ButtonWithTooltip
                        label="Replay & Analysis"
                        tooltip={
                          externalTextPastePercentage
                            ? 'More than 20% of characters copied externally'
                            : 'View Replay & Analytics'
                        }
                        style={{ color: externalTextPastePercentage ? 'red' : '' }}
                        icon={<AnalyticsIcon style={{ color: externalTextPastePercentage ? 'red' : '' }} />}
                        to={ROUTES.showAssignmentAnalytics(assignment.id, student.id)}
                      />
                    )}
                    {studentAssignment.status === UserAssignmentStatus.SUBMITTED && (
                      <ButtonWithTooltip
                        label="Unsubmit"
                        tooltip="Allow student to make some edits and re-submit assignment"
                        icon={
                          unsubmitMutation.isLoading ? <CircularProgress size={20} color="inherit" /> : <ReplayIcon />
                        }
                        onClick={() => {
                          unsubmitMutation.mutate({ assignmentId: assignment.id, studentId: student.id });
                        }}
                        disabled={unsubmitMutation.isLoading}
                      />
                    )}
                  </Stack>
                </Td>
              </Tr>
            );
          })}
        </TableBody>
      </Table>
    </Container>
  );
}
